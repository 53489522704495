// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_dR6eVX6p719f7uwcNP", // tron
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/9AQeVN6RoebQffig01", // tron

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Futuristic Art Gen AI 🤖",
    HOME_PAGE_TITLE: "Home - Futuristic Art Gen AI 🤖",
    DASHBOARD_PAGE_TITLE: "Home - Futuristic Art Gen AI 🤖",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Futuristic Art Gen AI 🤖",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to generate futuristic style art, enter a prompt and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "tron_gen_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/tron-gen-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;